/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Cağaloğlu’nda iki tane paşa konağından birisi olan Rauf Paşa Konağı, Osmanlı’nın son döneminde görülen bazı paşa konaklarının devlet dairesi olarak kullanılma geleneğine uygun olarak Nafia Nezareti’ne tahsis edilmiş, 1931-1932 yıllarına denk Nafia Nezareti dairesi olarak kullanılmıştır.  bu tarihten itibaren İstanbul Bayındırlık Müdürlüğü ve Maarif Müdürlüğü tarafından paylaşılmıştır. 1983'te, konağın kullanımı tamamen Milli Eğitim Bakanlığı'na tahsis edilmiştir. 2012 yılına kadar İstanbul İl Milli Eğitim Müdürlüğü olarak kullanılan binada, bu yıl yangın çıkmış ve bina harap bir hale gelmiştir.\nYarım bodrum kat dahil olmak üzere dört katlı yapı tamamen kagir, orijinal giriş cephesine göre aksiyal ve simetrik planlanmıştır. Plan merkezinde, sırt sırta vermiş iki merdiven evinin çevresini saran koridorla, dış cepheler boyunca sıralanmış odaların bağlantısı sağlanmaktadır.\nİki merdiven grubundan, orijinal giriş aksı karşısındaki üç kollu merdiven, ikinci katta yer alan korint başlıklı altı kolonla birlikte halen mevcuttur. Üç kollu yarım daire şeklinde olan diğer merdiven, tek kollu olarak değiştirilmiş, ikinci katta yarım daire boyunca sıralanan korint başlıklı sekiz kolondan ise sadece merdiven başındaki iki tanesi kalmıştır.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
